.row {
    display: flex;
    justify-content:space-between;
    width: 90%;
    margin: auto;
    padding: 0 10rem; 
    align-items: center;
    margin-bottom: 20rem;
}

.undraw {
    max-width: 23vw;
    max-height: 23vw;
    margin-left: 5rem;

}

.text {
    max-width: 45%;
}

.desc {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    padding: 0 3rem;
    border-radius: 10px;
    color: #cccccc;
    text-decoration: none;
    letter-spacing: 1px;
    z-index: 1;
}

.header {
    margin-top: 5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 21px;
    text-align: left;
    padding: 2rem 3rem;
    border-radius: 10px;
    color: #ffffff;
    text-decoration: none;
    letter-spacing: 1px;
    z-index: 1; 
}