.body {
  background-color: black;
  justify-content: center;
  height: 90vh;
}

header {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

h1{
  font-family: "Montserrat";
  font-size: 12vh;
  font-weight: bold;
  line-height: 100%
}

.innerbox{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  opacity: 0;
  animation: fadeInAnimation ease 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  white-space: pre-wrap;
  font-family: "Fira Code", monospace;
  font-size: 3vh;
  margin-top: 4vh;
  margin-bottom: 10vh;
  color: #d8d8d8;
  text-decoration: none;
}

#leftbutton{
  margin-right: 10vw;
}

#leftbutton:hover, #rightbutton:hover{
  opacity: 0.5;
}

.white {
  color: white;
}

.pink{
  color: #F72585;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
@keyframes pink-gray {
  from {color: rgba(264, 37, 133, 100%)}
  to {color: rgba(264, 37, 133, 60%)}
}