video {
    display: none;
}

.button_row{
  display: flex;
  justify-content: center;
}

.button{
  margin-top: 2.5vh;
  margin-left: 1vh;
  margin-right: 1vh;
  color: white;
  border-radius: 10px;
  padding: 0.5rem;
  font-family: "Fira Code";
}

#invert_button{
  margin-left: 2.5vh;
  margin-right: 2.5vh;
}

#disp {
    white-space: pre-wrap;
    display: block;
    text-align: center;
    border-radius: 20px;
    border: 2px solid white;
    padding: 2rem;
    text-align: center;
    font-family: monospace;
    font-size: 7px;
    color: white
}

.video-container {

    display: flex;
    justify-content: center;
    margin-top: 2.5vh;

}
