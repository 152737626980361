* {
    white-space: pre-line;
}

video {
    display: none;

}

.io {
    display: inline-block;
}

input {
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 30px;
    text-align: center;
}

#id-holder {
    color: white;
    margin: 5rem;
}

.video-pair {
    display: flex;
    justify-content: space-between;
    margin: 5rem;
}


#remote {
    white-space: pre-wrap;
    display: block;
    text-align: center;
    border-radius: 20px;
    border: 2px solid white;
    border: 2px solid #0C0C0C;;
    padding: 2rem;
    min-height: 25vw;
    min-width: 25vw;
    text-align: center;
    font-family: monospace;
    font-size: 7px;
    color: white
}

#disp {
    white-space: pre-wrap;
    display: block;
    text-align: center;
    border-radius: 20px;
    border: 2px solid #0C0C0C;;
    padding: 2rem;
    min-height: 25vw;
    min-width: 25vw;
    text-align: center;
    font-family: monospace;
    font-size: 7px;
    color: white
}

#clicker {
    font-family: "Cabin", sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 30px;
    
}