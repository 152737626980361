.card {
    margin: 4%;
    
    width: 33%;
    padding-top: 33%;

    text-align: center;
    background-color: #979797;
    border-radius: 20px;
    
    flex-grow: 1;
}
.card:hover {
    background-color: #f1f1f1;
}
