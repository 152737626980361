.title{
    text-align: center;
    font-family: "Montserrat";
    font-size: 9vh;
    font-weight: bold;

    margin-top: 45px;

    color: white;
    background-color: #0C0C0C;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.mainContainer{
    padding: 15px;
    height: 90vh;
    opacity: 0;
    animation: fadeInAnimation ease 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #0C0C0C;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
