.main {
    padding: 15px;
    width: 90%;
    margin: auto;
    height: 90vh;
    opacity: 0;
    border-radius: 20px;
    animation: fadeInAnimation ease 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-color:#0C0C0C;
    margin-bottom: 200px;
}

.HowText {
    text-align: center;
    font-family: "Montserrat";
    font-size: 9vh;
    font-weight: bold;

    margin-top: 30px;

    color: white;
    background-color: #0C0C0C;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px

}

.how-content {
    background-color: transparent;
    align-items: center;
    padding: 3rem;
    height: 70vh;
}

.ctrl {
    background-color: transparent;
    padding: 5rem;
    width: 60%;
    margin: auto;
}

.image {
    background-color: #0C0C0C;
    display:flex;
    padding-left: 5rem;
    padding-right: 5rem;
    justify-content: space-between;
}

img {
    
    margin-top: 5rem;
    border-radius: 20px;
    min-width: 20vw;
    max-height: 20vw;
}

.controller {
    display: flex;
    left: 0;
    background-color: #242424;
    justify-content:space-between;
    margin-top: 5vw;
    border-radius: 20px;
    padding: 3rem;

}

.how-text {
    background-color: #242424;
    text-align: center;
    vertical-align: middle;
    color: white;
    font-family: "Cabin";
    font-size: 25px;
    font-weight: 500;
}

.spacer {
    margin: 3000px
}